@charset "UTF-8";
.mean-container .mean-bar {
  background: transparent none repeat scroll 0 0;
  min-height: 60px;
  /*position: absolute;*/
  z-index: 9; }

.mean-container a.meanmenu-reveal {
  background-image: url("../images/elements/menu2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 40px auto;
  height: 35px;
  padding: 0;
  top: 15px;
  width: 60px; }
  .mean-container a.meanmenu-reveal.meanclose {
    background-image: url("../images/elements/close2.png");
    background-size: 30px auto;
    text-indent: -9999px !important; }
  .mean-container a.meanmenu-reveal span {
    display: none; }

.mean-container .mean-nav {
  margin-top: 65px; }
  .mean-container .mean-nav ul li a {
    background-color: #eee;
    border-top: 1px solid #bbb;
    color: #232323;
    letter-spacing: 0.7px;
    line-height: 1;
    padding: 1em 5%; }
    .mean-container .mean-nav ul li a:hover {
      background-color: #ccc; }
  .mean-container .mean-nav ul .mean-last {
    box-shadow: 0 7px 11px -7px #777; }

body {
  overflow: hidden;
  width: 100%; }

#header .region-header {
  padding: 0;
  width: auto; }

#block-block-24 h2 {
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 10; }

#menu-bar {
  padding: 90px 0 0; }

#block-block-2 {
  margin: auto;
  padding: 0 10px 50px;
  width: auto; }
  #block-block-2 h1 {
    font-size: 28px;
    margin: 20px 0;
    width: 55%; }
  #block-block-2 .image {
    height: 310px;
    margin: -20px 0 0 53%;
    width: 45%; }
  #block-block-2 .text {
    font-size: 16px;
    width: 55%; }
  #block-block-2 .ssilka {
    width: 50%; }
    #block-block-2 .ssilka a {
      font-size: 18px; }
  #block-block-2 .podpis {
    font-size: 15px;
    margin: 10px 0 0 40px; }

#block-block-26 {
  padding: 70px 10px; }
  #block-block-26 .content {
    margin: auto;
    width: auto; }
  #block-block-26 h2 {
    font-size: 20px;
    line-height: 1.5;
    width: auto; }
    #block-block-26 h2::after {
      margin: 20px 0 0 43%;
      width: 12%; }
  #block-block-26 .text {
    width: auto; }
  #block-block-26 .ssilka {
    padding: 40px 0 10px; }
    #block-block-26 .ssilka a {
      font-size: 18px; }

#block-block-27 {
  background-attachment: scroll;
  padding: 70px 0; }
  #block-block-27 .content {
    margin: auto;
    width: auto; }
  #block-block-27 h2 {
    font-size: 34px;
    line-height: 1.1;
    padding: 0 0 0 10px;
    width: 50%; }
    #block-block-27 h2::after {
      margin: 20px 0 0 40%;
      width: 10%; }
  #block-block-27 .text {
    font-size: 16px;
    padding: 0 0 0 10px;
    width: 50%; }

#block-block-28 {
  padding: 70px 10px; }
  #block-block-28 .content {
    padding: 0 0 0 55%;
    width: 45%; }
  #block-block-28 .image {
    background-image: url("../images/diagram.jpg");
    background-position: center center;
    background-size: 100% auto;
    height: 350px;
    margin: 0 0 0 -54%;
    position: absolute;
    text-indent: -9999px;
    width: 50%; }
  #block-block-28 .legend {
    left: 0;
    width: 45%;
    margin: 0; }
    #block-block-28 .legend .leglast {
      font-size: 14px;
      letter-spacing: 0.7px;
      margin: 350px 0 0 20px; }
  #block-block-28 .leg1,
  #block-block-28 .leg2,
  #block-block-28 .leg3,
  #block-block-28 .leg4,
  #block-block-28 .leg5,
  #block-block-28 .leg6,
  #block-block-28 .leg7 {
    display: none; }
  #block-block-28 h2 {
    font-size: 32px; }
    #block-block-28 h2::after {
      width: 10%; }
  #block-block-28 .text P {
    font-size: 15px;
    line-height: 1.3; }
  #block-block-28 .text li {
    font-size: 15px;
    line-height: 1.3;
    margin: 0 0 3px; }

#block-block-30 {
  padding: 70px 20px; }
  #block-block-30 .content {
    margin: auto;
    width: auto; }
  #block-block-30 .image {
    background-position: 0 0;
    background-size: auto 100%;
    height: 600px;
    margin: 0 0 0 57%;
    width: 40%; }
  #block-block-30 h2 {
    font-size: 34px;
    line-height: 1.1;
    margin: 0 0 50px;
    width: 50%; }
    #block-block-30 h2::after {
      margin: 20px 0 0 0;
      width: 10%; }
  #block-block-30 ul {
    font-size: 14px;
    width: 50%; }
    #block-block-30 ul li {
      margin: 0 0 5px; }
  #block-block-30 .ssilka a {
    font-size: 18px; }

#block-block-31 {
  padding: 70px 10px; }
  #block-block-31 .content {
    margin: auto;
    width: auto; }
  #block-block-31 h2 {
    font-size: 34px;
    line-height: 1.1;
    margin: 0 auto 50px;
    text-align: center;
    width: 90%; }
    #block-block-31 h2::after {
      margin: 20px 0 0 40%;
      width: 10%; }
  #block-block-31 p {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 auto;
    text-align: center;
    width: 90%; }

#block-block-32 {
  padding: 70px 10px; }
  #block-block-32 .content {
    margin: auto;
    width: auto; }
  #block-block-32 h2 {
    font-size: 34px;
    margin: 0 0 70px; }
    #block-block-32 h2::after {
      margin: 20px 0 0 45%;
      width: 10%; }
  #block-block-32 tbody td {
    font-size: 14px;
    line-height: 1.5;
    padding: 15px 10px; }
  #block-block-32 .links a {
    font-size: 18px; }

#block-block-33, #block-block-36 {
  padding: 70px 10px; }
  #block-block-33 .content, #block-block-36 .content {
    margin: auto;
    width: auto; }
  #block-block-33 h2, #block-block-36 h2 {
    font-size: 34px; }
    #block-block-33 h2::after, #block-block-36 h2::after {
      margin: 20px 0 0 44%;
      width: 10%; }
  #block-block-33 .text, #block-block-36 .text {
    font-size: 22px; }
  #block-block-33 .ssilka a, #block-block-36 .ssilka a {
    font-size: 18px; }

#footer {
  padding: 20px 10px; }
  #footer .region {
    margin: auto;
    width: auto; }

#block-block-34 h2 a {
  margin: 40px auto 20px;
  width: 300px; }

#block-superfish-2 {
  float: none;
  height: 20px;
  margin: 12px 0 0; }
  #block-superfish-2 #superfish-2 {
    text-align: center; }
    #block-superfish-2 #superfish-2 li {
      display: inline-block;
      float: none;
      margin: 0; }
    #block-superfish-2 #superfish-2 a {
      font-size: 15px; }

.not-front .mean-bar {
  border-bottom: 2px dashed #bbb; }

.not-front #header {
  border: none;
  min-height: 60px; }
  .not-front #header .region-header {
    padding: 0; }

.not-front #content-column {
  margin: auto;
  padding: 0 10px;
  width: auto; }

.page-node-395 h1#page-title {
  font-size: 40px;
  padding: 30px 0 50px; }

.page-node-395 .node .field-name-body .table > div {
  height: 350px;
  width: 230px; }

.page-node-395 .node .field-name-body .table .zagolovok {
  font-size: 28px;
  padding: 20px 0 120px; }
  .page-node-395 .node .field-name-body .table .zagolovok::after {
    border-left: 115px solid transparent;
    border-right: 115px solid transparent;
    border-top: 25px solid #55c862;
    margin: 120px 0 0; }

.page-node-395 .node .field-name-body .table .opis {
  font-size: 14px;
  margin: -105px auto 0;
  padding: 0 10px;
  width: 210px; }

.page-node-395 .node .field-name-body .table .price {
  font-size: 45px;
  margin: -80px 0 0;
  width: 230px; }
  .page-node-395 .node .field-name-body .table .price span {
    font-size: 16px; }

.page-node-395 .node .field-name-body .table .text {
  padding: 40px 0 0; }
  .page-node-395 .node .field-name-body .table .text ul {
    padding: 0 10px 0 40px; }
    .page-node-395 .node .field-name-body .table .text ul li {
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 1.5;
      margin: 0 0 10px; }
      .page-node-395 .node .field-name-body .table .text ul li::before {
        height: 15px;
        margin: 3px 0 0 -25px;
        width: 15px; }

.page-node-395 .node .field-name-body .table2 ul {
  padding: 0 20px 0 40px; }
  .page-node-395 .node .field-name-body .table2 ul li {
    background-size: 23px auto;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    padding: 0 0 0 40px; }

.page-node-395 .node .field-name-body .ssilki {
  margin: 30px 0 70px; }
  .page-node-395 .node .field-name-body .ssilki a {
    font-size: 18px; }

.page-node-396 .node .field-name-body p {
  line-height: 1.5; }

#block-block-37, #block-block-38, #block-block-39 {
  margin: 0 auto 70px;
  width: auto; }
  #block-block-37 input.button, #block-block-38 input.button, #block-block-39 input.button {
    font-size: 18px;
    margin: 20px 0 10px; }

#block-block-37 {
  padding: 50px 10px; }

.page-node-396 h1#page-title,
.page-node-397 h1#page-title,
.page-node-399 h1#page-title {
  font-size: 34px; }

.page-node-418 #block-block-49 h2 {
  width: 450px; }

/*Афоризмы в футурe*/
#footer #block-views-aforizmy-block .view-content .field-content {
  color: #fff; }

/*контакты*/
.page-node-407 #content {
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 80px; }

#block-views-razrabotka-block {
  padding-top: 10px;
  padding-bottom: 20px; }
  #block-views-razrabotka-block .views-field-title-field-et {
    font-size: 30px; }
  #block-views-razrabotka-block .views-field-body-et {
    font-size: 14px; }
