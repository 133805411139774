.mean-container {
    .mean-bar{
        background: transparent none repeat scroll 0 0;
        min-height: 60px;
        /*position: absolute;*/
        z-index: 9;
    }
    a.meanmenu-reveal{
        background-image: url("../images/elements/menu2.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 40px auto;
        height: 35px;
        padding: 0;
        top: 15px;
        width: 60px;
        &.meanclose{
            background-image: url("../images/elements/close2.png");
            background-size: 30px auto;
            text-indent: -9999px !important;
        }
        span{
            display: none;
        }
    }
    .mean-nav{
        margin-top: 65px;
        ul{
            li a{
                background-color: #eee;
                border-top: 1px solid #bbb;
                color: #232323;
                letter-spacing: 0.7px;
                line-height: 1;
                padding: 1em 5%;
                &:hover{
                    background-color: #ccc;
                }
            }
            .mean-last{
                box-shadow: 0 7px 11px -7px #777;
            }
        }
    }
}
body {
    overflow: hidden;
    width: 100%;
}
#header {
    .region-header {
        padding: 0;
        width: auto;
    }
}
#block-block-24 {
    h2 {
        left: 10px;
        position: absolute;
        top: 10px;
        z-index: 10;
    }
}
#menu-bar {
    padding: 90px 0 0;
}
#block-block-2 {
    margin: auto;
    padding: 0 10px 50px;
    width: auto;
    h1{
        font-size: 28px;
        margin: 20px 0;
        width: 55%;
    }
    .image {
        height: 310px;
        margin: -20px 0 0 53%;
        width: 45%;
    }
    .text {
        font-size: 16px;
        width: 55%;
    }
    .ssilka {
        width: 50%;
        a{
            font-size: 18px;
        }
    }
    .podpis {
        font-size: 15px;
        margin: 10px 0 0 40px;
    }
}
#block-block-26 {
    padding: 70px 10px;
    .content {
        margin: auto;
        width: auto;
    }
    h2{
        font-size: 20px;
        line-height: 1.5;
        width: auto;
        &::after{
            margin: 20px 0 0 43%;
            width: 12%;
        }
    }
    .text{
        width: auto;
    }
    .ssilka{
        padding: 40px 0 10px;
        a{
            font-size: 18px;
        }
    }
}
#block-block-27 {
    background-attachment: scroll;
    padding: 70px 0;
    .content {
        margin: auto;
        width: auto;
    }
    h2{
        font-size: 34px;
        line-height: 1.1;
        padding: 0 0 0 10px;
        width: 50%;
        &::after{
            margin: 20px 0 0 40%;
            width: 10%;
        }
    }
    .text{
        font-size: 16px;
        padding: 0 0 0 10px;
        width: 50%;
    }
}
#block-block-28 {
    padding: 70px 10px;
    .content{
        padding: 0 0 0 55%;
        width: 45%;
    }
    .image {
        background-image: url("../images/diagram.jpg");
        background-position: center center;
        background-size: 100% auto;
        height: 350px;
        margin: 0 0 0 -54%;
        position: absolute;
        text-indent: -9999px;
        width: 50%;
    }
    .legend {
        left: 0;
        width: 45%;
        margin: 0;
        .leglast {
            font-size: 14px;
            letter-spacing: 0.7px;
            margin: 350px 0 0 20px;
        }
    }
    .leg1,
    .leg2,
    .leg3,
    .leg4,
    .leg5,
    .leg6,
    .leg7{
        display: none;
    }
    h2{
        font-size: 32px;
        &::after{
            width: 10%;
        }
    }
    .text{
        P{
            font-size: 15px;
            line-height: 1.3;
        }
        li {
            font-size: 15px;
            line-height: 1.3;
            margin: 0 0 3px;
        }
    }
}
#block-block-30 {
    padding: 70px 20px;
    .content {
        margin: auto;
        width: auto;
    }
    .image {
        background-position: 0 0;
        background-size: auto 100%;
        height: 600px;
        margin: 0 0 0 57%;
        width: 40%;
    }
    h2{
        font-size: 34px;
        line-height: 1.1;
        margin: 0 0 50px;
        width: 50%;
        &::after{
            margin: 20px 0 0 0;
            width: 10%;
        }
    }
    ul {
        font-size: 14px;
        width: 50%;
        li{
            margin: 0 0 5px;
        }
    }
    .ssilka a{
        font-size: 18px;
    }
}
#block-block-31 {
    padding: 70px 10px;
    .content {
        margin: auto;
        width: auto;
    }
    h2 {
        font-size: 34px;
        line-height: 1.1;
        margin: 0 auto 50px;
        text-align: center;
        width: 90%;
        &::after {
            margin: 20px 0 0 40%;
            width: 10%;
        }
    }
    p{
        font-size: 18px;
        line-height: 1.5;
        margin: 0 auto;
        text-align: center;
        width: 90%;
    }
}
#block-block-32 {
    padding: 70px 10px;
    .content {
        margin: auto;
        width: auto;
    }
    h2{
        font-size: 34px;
        margin: 0 0 70px;
        &::after {
            margin: 20px 0 0 45%;
            width: 10%;
        }
    }
    tbody{
        td {
            font-size: 14px;
            line-height: 1.5;
            padding: 15px 10px;
        }
    }
    .links{
        a{
            font-size: 18px;
        }
    }
}
#block-block-33, #block-block-36 {
    padding: 70px 10px;
    .content{
        margin: auto;
        width: auto;
    }
    h2{
        font-size: 34px;
        &::after {
            margin: 20px 0 0 44%;
            width: 10%;
        }
    }
    .text{
        font-size: 22px;
    }
    .ssilka{
        a{
            font-size: 18px;
        }
    }
}
#footer {
    padding: 20px 10px;
    .region {
        margin: auto;
        width: auto;
    }
}
#block-block-34{
    h2 {
        a {
            margin: 40px auto 20px;
            width: 300px;
        }
    }
}
#block-superfish-2{
    float: none;
    height: 20px;
    margin: 12px 0 0;
    #superfish-2{
        text-align: center;
        li{
            display: inline-block;
            float: none;
            margin: 0;
        }
        a {
            font-size: 15px;
        }
    }
}

.not-front{
    .mean-bar{
        border-bottom: 2px dashed #bbb;
    }
    #header{
        border: none;
        min-height: 60px;
        .region-header {
            padding: 0;
        }
    }
    #content-column{
        margin: auto;
        padding: 0 10px;
        width: auto;
    }
}
.page-node-395{
    h1#page-title{
        font-size: 40px;
        padding: 30px 0 50px;
    }
    .node .field-name-body{
        .table{
            & > div{
                height: 350px;
                width: 230px;
            }
            .zagolovok{
                font-size: 28px;
                padding: 20px 0 120px;
                &::after{
                    border-left: 115px solid transparent;
                    border-right: 115px solid transparent;
                    border-top: 25px solid #55c862;
                    margin: 120px 0 0;
                }
            }
            .opis{
                font-size: 14px;
                margin: -105px auto 0;
                padding: 0 10px;
                width: 210px;
            }
            .price{
                font-size: 45px;
                margin: -80px 0 0;
                width: 230px;
                span{
                    font-size: 16px;
                }
            }
            .text{
                padding: 40px 0 0;
                ul{
                    padding: 0 10px 0 40px;
                    li{
                        font-size: 14px;
                        letter-spacing: 0.2px;
                        line-height: 1.5;
                        margin: 0 0 10px;
                        &::before{
                            height: 15px;
                            margin: 3px 0 0 -25px;
                            width: 15px;
                        }
                    }
                }
            }
        }
        .table2{
            ul{
                padding: 0 20px 0 40px;
                li{
                    background-size: 23px auto;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    line-height: 1.5;
                    padding: 0 0 0 40px;
                }
            }
        }
        .ssilki{
            margin: 30px 0 70px;
            a{
                font-size: 18px;
            }
        }
    }
}
.page-node-396{
    .node {
        .field-name-body{
            p{
                line-height: 1.5;
            }
        }
    }
}
#block-block-37, #block-block-38, #block-block-39 {
    margin: 0 auto 70px;
    width: auto;
    input.button{
        font-size: 18px;
        margin: 20px 0 10px;
    }
}
#block-block-37 {
    padding: 50px 10px;
}
.page-node-396,
.page-node-397,
.page-node-399{
    h1#page-title {
        font-size: 34px;
    }   
}

.page-node-418 {
    #block-block-49{
        h2 {
            width: 450px;
        }
    }
}

/*Афоризмы в футурe*/
#footer {
    #block-views-aforizmy-block {
        .view-content {
            .field-content {
                color: #fff;
            }
        }
    }
}

/*контакты*/
.page-node-407 {
    #content {
        padding: 0 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-bottom: 80px;
    }
}

// Block - The hood on the Lexus NX GreyWolf
#block-views-razrabotka-block{
    padding-top: 10px;
    padding-bottom: 20px;
    // Заголовок
    .views-field-title-field-et{
        font-size: 30px;
    }
    // Текст с описанием
    .views-field-body-et{
        font-size: 14px;
    }
}